/**
 * @desc App View
 *
 *
 */
define('views/admin-page-single',[

    'jquery',
    'underscore',
    'backbone',
    'utils',
    'underscore.string',
    'models/core',
    'views/edit-page-blocks'

],	function($, _, Backbone, Utils, _string, Model, EditPageBlocksView){

    'use strict';
    console.log(Utils)

    return Backbone.View.extend({

        // setup DOM Elements
        el : $('body'),

        // bind Events
        events: {
            'click .trigger-save-page': 'save',
            'click .trigger-delete-page' : 'delete',
            'keyup #title' : 'generateSlug',
            'keyup #slug' : 'generateSlug'
        },

        /**
         * initialize()
         * @desc initialize the view
         *
         * @param options
         */
        initialize: function(options){

            console.log('---Page Admin - Single View---');

            this.messages = Utils.getConfig().messages;

            this.user = Utils.getData('user');

            this.model = new Model(Utils.getData('page'), {modelName: 'pages'});
            this.is_new_page = this.model.isNew();
            this.existing_slug = this.model.get('slug');

            new EditPageBlocksView({
                pageID: this.model.get('id'),
                pageBlocks: this.model.get('pageBlocks')
            });

        },

        /**
         * render()
         * @desc Render the view
         *
         *
         */
        render: function(){

            // No render

        },

        /**
         *
         * @desc save series
         *
         * @param event
         */
        save: function ( event ){

            var _this = this;

            event.preventDefault();

            var $clicked = $(event.currentTarget);
            var $form = $('#form-page');

            var $title = $('input[name="title"]');
            var title = $title.val();
            var $slug = $('input[name="slug"]');
            var slug = $slug.val();

            if(!title){
                Utils.alert(this.messages.requiredTitle);
                $title.focus();
                return;
            }

            if(!slug){
                Utils.alert(this.messages.requiredSlug);
                $slug.focus();
                return;
            }

            // If the slug is being updated on an existing page, warn the user.
            if(!this.is_new_page && slug !== this.existing_slug){
                var forbiddenSlugCheck = window.confirm('Updating a page slug will make old URLs obsolete, are you sure you want to do this? Not recommended for popular pages.');
                if(!forbiddenSlugCheck){
                    Utils.buttonReset($clicked);
                    $slug.focus();
                    return;
                }
            }

            // If the slug is important, warn the user.
            if (_.contains(['/', 'videos', 'series', 'admin', 'projects', 'store'], slug)) {
                var slugCheck = window.confirm('Warning: The slug you are using will override built-in pages existing in this application, are you sure you want to do this? Not recommended.');

                if(!slugCheck){
                    Utils.buttonReset($clicked);
                    $slug.focus();
                    return;
                }
            }

            Utils.buttonLoading($clicked);
            this.model.$button = $clicked;

            var data = $form.serializeObject();

            this.model.save(data, {
                success: function(){
                    Utils.alert(_this.is_new_page ? _this.messages.newPageSaved : _this.messages.pageSaved);
                    if(_this.is_new_page){
                        window.location = '/admin/pages/' + _this.model.get('id');
                    } else {
                        Utils.buttonReset($clicked);
                    }
                },
                patch: function () {
                    // If the model isn't new, let's send a patch request to avoid pageBlock hiccups.
                    return !_this.is_new_page;
                }
            });

        },

        delete : function( event ) {

            var _this = this;

            event.preventDefault();

            var $clicked = $(event.currentTarget);

            Utils.buttonLoading($clicked);

            var check = window.confirm('Are you sure you want to delete this page?');

            if(!check){
                Utils.buttonReset($clicked);
                return;
            }

            this.model.destroy({success: function(){
                Utils.alert(_this.messages.pageDeleted);
                window.location = '/admin/pages';
            }});

        },


        generateSlug: function( event ){

            event.preventDefault();

            var _this = this;

            Utils.delay(function(){

                if(_this.is_new_page || $(event.currentTarget).attr('id') === 'slug'){
                    var title = $(event.currentTarget).val();
                    var slug = _string.slugify(title);
                    _this.model.set({
                        slug: slug
                    });
                    $('#slug').val(slug);

                }

            }, 500);

        }


    });

});
