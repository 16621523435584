/**
 * @desc Client Side Router
 *
 *
 */
define('router',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'tracking'

],	function($, _, Backbone, Utils, Tracking){

		var AppRouter = Backbone.Router.extend({

			routes: {
				''						: 'home',
        'login' 				: 'auth',
        'signup' 				: 'auth',
        'reset/:token' 			: 'auth',
        'forgot' 				: 'auth',
        'education/:all' 		: 'auth',
				'videos'				: 'video-all',
				'video'					: 'video-all',
				'video/:slug'			: 'video-single',
				'category/:slug'		: 'video-category',
				'tag/:slug'				: 'video-category',
				'project'				: 'video-projects',
				'projects'				: 'video-projects',
				'series'				: 'series-all',
				'series/:slug'			: 'series-single',
				'series/:slug/:video'	: 'series-single-item',
				'account' 				: 'account',
				'account/setup' 		: 'account-setup',
				'admin'					: 'admin',
				'admin/pages/:id'		: 'admin-page-single',
				'admin/series'			: 'admin-series',
				'admin/series/:id'		: 'admin-series-single',
				'admin/video/:slug'		: 'admin-single',
				'admin/taxonomy/:id'	: 'admin-taxonomy-single',
                ':slug'					: 'home',
			}
		});

		var initialize = function(){

			var router = new AppRouter;

			/**
			 * @desc home
			 */
			router.on('route:home', function(){

				require(['views/app'], function (AppView) {
					var appView = new AppView({});
				});

				require(['views/videos'], function (View) {
					var view = new View({
						templateType: 'featured',
						pagination: true,
						fetchOptions: {
							reset: true,
							data: {
								pageSize: 13,
								where:{
									active: true,
									type: 'project'
								},
								orderBy: 'featured_order',
								order: 'asc'}
						}
					});
				});

			});

			/**
			 * @desc admin
			 */
			router.on('route:admin', function(){

				require(['views/videos'], function (View) {
					var view = new View({
						admin: true,
						pagination: true,
						fetchOptions: {
							data: {
								pageSize: 25,
								searchTerm: Utils.getQueryVariable('search'),
								searchKeys: 'all'
							}
						}
					});
				});

			});

			/**
			 * @desc admin single
			 */
			router.on('route:admin-single', function(){

				require(['views/admin-single'], function (View) {
					var view = new View();
				});

			});

			/**
			 * @desc admin taxonomy single
			 */
			router.on('route:admin-taxonomy-single', function(){

				require(['views/admin-taxonomy-single'], function (View) {
					var view = new View({
						admin: true
					});
				});

			});

            /**
             * @desc admin home edit
             */
            router.on('route:admin-page-single', function(){
                require(['views/admin-page-single'], function (View) {
                    var view = new View({
						admin: true,
						page: Utils.getData('page')
                    });
                });
            });

			/**
			 * @desc admin series
			 */
			router.on('route:admin-series', function(){
				require(['views/series'], function (View) {
					var view = new View({admin: true});
				});
			});

			/**
			 * @desc admin series single
			 */
			router.on('route:admin-series-single', function(){
				require(['views/admin-series-single'], function (View) {
					var view = new View({
						admin: true
					});
				});
			});

			/**
			 * @desc account
			 */
			router.on('route:account', function(){

				require(['views/account'], function (View) {
					var view = new View({});
				});

			});

			/**
			 * @desc account setup
			 */
			router.on('route:account-setup', function(){

				require(['views/account-setup'], function (View) {
					var view = new View({});
				});

			});


			/**
			 * @desc videos all
			 */
			router.on('route:video-all', function(){

				require(['views/videos'], function (View) {
					var view = new View({
						pagination: Utils.getData('pagination'),
						fetchOptions: Utils.getData('paginationOptions')
					});
				});

				if(Utils.getQueryVariable('search') || Utils.getQueryVariable('type')){
					Tracking.track({
						type: 'search',
						user_id: Utils.getData('user') ? Utils.getData('user').id : null,
						meta: {term: Utils.getQueryVariable('search')}
					});
				}
			});

			/**
			 * @desc category
			 */
			router.on('route:video-category', function(){

				if(Utils.getData('category').display_as === 'series_only'){
					require(['views/series'], function (View) {
						var view = new View({
							$view : $('#find-content-video'),
							admin: false,
							series: Utils.getData('content')
						});
					});
				} else if(Utils.getData('category').display_as === 'multi_series') {
					require(['views/multi_series'], function (View) {
						var view = new View({
							$view : $('#find-content-video'),
							admin: false,
							series: Utils.getData('content')
						});
					});
				} else {

					require(['views/videos'], function (View) {

						var view = new View({
							$view : $('#find-content-video'),
							videos: Utils.getData('content'),
							pagination: Utils.getData('pagination'),
							fetchOptions: Utils.getData('paginationOptions')
						});

					});
				}
			});

			/**
			 * @desc projects all
			 */
			router.on('route:video-projects', function(){

				require(['views/videos'], function (View) {
					var view = new View({
						templateType : 'featured',
						pagination: Utils.getData('pagination'),
						fetchOptions: Utils.getData('paginationOptions')
					});
				});

			});

			/**
			 * @desc series all
			 */
			router.on('route:series-all', function(){
				require(['views/series'], function (View) {
					var view = new View({admin: false});
				});
			});

			/**
			 * @desc series all
			 */
			router.on('route:series-single', function(){
				require(['views/videos'], function (View) {
					var view = new View({
            series: Utils.getData('series'),
					});
				});
			});

			/**
			 * @desc video single
			 */
			router.on('route:series-single-item', function(){

				require(['views/auth'], function (AuthView) {
					var authView = new AuthView({});
				});

				require(['views/video'], function (View) {
					var view = new View({
						fetchOptions: {}
					});
				});

			});

			/**
			 * @desc video single
			 */
			router.on('route:video-single', function(){

				require(['views/auth'], function (AuthView) {
					var authView = new AuthView({});
				});

				require(['views/video'], function (View) {
					var view = new View({
						fetchOptions: {}
					});
				});

			});

            /**
             * @desc auth
             */
			router.on('route:auth', function(){
				require(['views/auth'], function (AuthView) {
					var authView = new AuthView({});
				});

			});

			/**
			 * @desc all
			 */
			require(['views/email-signup'], function (View) {
				var view = new View({});
			});

			// start history
			Backbone.history.start({pushState: true});

			// check browser
			Utils.checkBrowser();

			// check alerts and reset
			Utils.resetAlert(5000);

		};

	return {

		initialize : initialize

	};

});

