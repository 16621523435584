/**
 * @desc Edit Page Blocks View
 *
 *
 */
define('views/edit-page-blocks',[

    'jquery',
    'underscore',
    'underscore.string',
    'backbone',
    'utils',
    'sortable',
    'models/core',
    'collections/core',
    'text!templates/edit-page-blocks.html',
    'views/modal-single',
    './edit-page-block'

],	function($, _, _string, Backbone, Utils, Sortable, Model, Collection, Template, SingleModalView, EditPageBlockView){

    'use strict';

    return Backbone.View.extend({

        // setup DOM Elements
        el : $('body'),
        $view: $('#find-edit-page-blocks'),
        template: _.template(Template),

        // bind Events
        events: {
            'click .trigger-new-page-block-modal': 'addBlock',
            'click .page-block-edit' : 'editBlock',
            'click .page-block-delete' : 'removeBlock'
        },

        modelName: 'page_blocks',

        /**
         * initialize()
         * @desc initialize the view
         *
         * @param options
         */
        initialize: function(options){

            var _this = this;

            // Initializing the Single Modal view cuz we know we'll need it.
            this.singleModalView = new SingleModalView();

            this.messages = Utils.getConfig().messages;
            this.pageID = options.pageID;
            this.pageBlocks = options.pageBlocks;

            this.collection = new Collection(this.pageBlocks, {
                modelName: 'page_blocks',
                sortHandle: '.find-sort-block'
            });

            // Being explicit on change:block because we don't want the collection to have to listen to all 'change' events
            this.collection.on('add destroy change:block', function () {
                _this.render();
            });

            this.render();

        },

        /**
         * render()
         * @desc Render the view
         *
         *
         */
        render: function(){

            var _this = this;
            var sorted = _.sortBy(this.collection.toJSON(), function(item) { return parseInt(item.order) });

            // Render the block list collection
            this.$view.empty().append(this.template({
                collection: sorted
            })).ready(function(){
                // The button is rendered on the server, but we
                $('.trigger-new-page-block-modal').show();
            });


            if(this.collection.length){
                // If there are page blocks, make them sortable.
                var sortable = new Sortable($('#admin-page-blocks')[0], {
                    sort: true,
                    delay: 0,
                    disabled: false,
                    store: null,
                    animation: 150,
                    draggable: ".find-sort-block",
                    handle: '.find-sort-block-handle',
                    scroll: true,
                    scrollSensitivity: 30,
                    scrollSpeed: 10,

                    onEnd: function (event) {
                        _this.collection.updateOrder();
                        Utils.alert(_this.messages.blockOrderSaved, 2000);
                    }
                });
            }

        },

        addBlock: function ( ) {

            var _this = this;

            // Creates a new, empty 'page_blocks' model
            var newBlockModel = new Model({ page_id: this.pageID }, { modelName: _this.modelName });

            // Initializes and renders the EditPageBlock modal
            this.editPageBlockView = new EditPageBlockView({ model: newBlockModel, collection: this.collection, pageID: this.pageID });
        },

        editBlock: function ( event ) {

            var _this = this;

            var $clicked = $(event.currentTarget);
            var editBlockID = $clicked.attr('data-id');

            var model = this.collection.get(editBlockID);

            _this.editPageBlockView = new EditPageBlockView({ model: model, collection: this.collection, pageID: this.pageID });
        },

        removeBlock: function ( event ) {

            var _this = this;

            var $clicked = $(event.currentTarget);
            var deleteBlockID = $clicked.attr('data-id');

            var model = this.collection.get(deleteBlockID);

            var check = window.confirm('Are you sure you want to delete this page block?');

            if(check){
                model.destroy({
                    success: function(model, response){
                        // Collection is re-rendered automatically
                    },
                    error: function(model, response){
                        console.error('Error deleting the Block');
                    }
                });
            }


        }

    });

});
