requirejs.config({
	paths: {
		'config': 'empty:',
		'data' : 'empty:',
		'jquery' : '../node_modules/jquery/dist/jquery',
		'underscore' : '../node_modules/underscore/underscore-min',
		'underscore.string' : '../node_modules/underscore.string/dist/underscore.string.min',
		'backbone' : '../node_modules/backbone/backbone',
		'utils' : 'libs/utils',
		'tracking' : 'libs/track',
		'bowser' : '../node_modules/bowser/src/bowser',
		'bootstrap' : '../stylesheets/_scss/_07-vendors/bootstrap/dist/js/bootstrap.min',
		'moment' : '../node_modules/moment/min/moment.min',
		'videojs' : '../node_modules/video.js/dist/video',
		'videojs-youtube' : '../node_modules/videojs-youtube/dist/Youtube',
		'videojs-contrib-ads' : '../node_modules/videojs-contrib-ads/dist/videojs.ads',
		'videojs-ima' : 'libs/videojs-ima/src/videojs.ima',
		'imasdk': '//imasdk.googleapis.com/js/sdkloader/ima3',
		'vide': '../node_modules/vide/dist/jquery.vide',
		'form-serializer' : '../node_modules/form-serializer/dist/jquery.serialize-object.min',
		'dropzone' : '../node_modules/dropzone/dist/dropzone-amd-module',
		'sortable' : '../node_modules/sortablejs/Sortable',
    'Flickity' : '../node_modules/flickity/dist/flickity.pkgd',
		'js-cookie' : '../node_modules/js-cookie/src/js.cookie',
		'sentry' : '../node_modules/@sentry/browser/dist/index',
		'sentry-tracing' : '../node_modules/@sentry/tracing/dist/browser/index',
		'waypoints' : '../node_modules/waypoints/lib/noframework.waypoints'
	},
	map: {
		"*": {
			'video.js': 'videojs'
		}
	},

	shim: {
		"bootstrap" : {
			deps: ["jquery"]
		},
		"videojs-contrib-ads": {
			deps: ["videojs-global"],
		},
		'videojs-ima' : {
			deps: ["videojs-contrib-ads", "imasdk"],
		},
		'form-serializer' : {
			deps: ['jquery']
		},
        'flickity' : {
            deps: ['jquery'],
			exports: 'flickity'
        },
		'vide' : {
			deps: ['jquery'],
			exports: 'vide'
		}
	},

});

// This helps our ad modules by putting videojs in the window object prior to initiating dependencies
define("videojs-global", ["videojs"], function(videojs) {
	window.videojs = videojs;
	return videojs;
});

require([ 'app'], function(App){

	'use strict';

	App.initialize();

});
define("main", function(){});

