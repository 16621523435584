/**
 * @desc Edit Page Block Modal view
 *
 *
 */
define('views/edit-page-block',[

    'jquery',
    'underscore',
    'underscore.string',
    'backbone',
    'utils',
    'sortable',
    'models/core',
    'collections/core',
    'text!templates/edit-page-block.html',
    'views/modal-single',
    'text!templates/form-select-collection.html',
    'text!templates/edit-page-block-item.html'

],	function($, _, _string, Backbone, Utils, Sortable, Model, Collection, Template, SingleModalView, FormSelectTemplate, EditPageBlockItemTemplate){

    'use strict';

    return Backbone.View.extend({

        el: $('body'),
        $view: $('#modal-single-body'),
        template: _.template(Template),
        selectCollectionTemplate: _.template(FormSelectTemplate),
        editPageBlockItemTemplate: _.template(EditPageBlockItemTemplate),

        // bind Events
        events: {
            'submit #form-page-block': 'saveChanges',
            'change #type': 'changeType',
            'click #add-new-item': 'addNewItem',
            'click .item-delete': 'deleteItem',
            'change .trigger-dropzone-update' : 'setImage',
            'hidden.bs.modal #modal-single' : 'closedModal'
        },

        /**
         * initialize()
         * @desc initialize the view
         *
         * @param options
         */
        initialize: function(options){

            var _this = this;

            this.$singleModal = $('#modal-single');

            console.log('Edit Single block view...');

            this.messages = Utils.getConfig().messages;

            this.pageID = options.pageID;

            this.render();

        },

        /**
         * render()
         * @desc Render the view
         *
         *
         */
        render: function(){

            var _this = this;

            var blockType = this.model.get('type');

            // Replace the modal content and show itself
            this.$view.empty().append(this.template({
                page_block: this.model.toJSON(),
                categories: this.categories,
                pageID: this.pageID
            })).ready(function(){
                if(blockType){
                    _this.showInputs(blockType);
                }
                _this.$singleModal.modal('show');
            });
        },

        showInputs: function( type ){

            var _this = this;

            console.log('type', type)

            // Hide/Show page block inputs based on type
            switch (type) {
                case 'slider':
                case 'cta':
                case 'custom':

                    console.log('case', type)

                    var items = this.model.get('block_items_array');

                    $('#find-edit-items').empty();

                    if(items){

                        _.each(items, function( item, index ){

                            if(typeof item === 'string')
                            {
                                item = JSON.parse(item);
                            }

                            item.id = index;

                            $('#find-edit-items').append(_this.editPageBlockItemTemplate({
                                item: item
                            }));
                        });

                        _.each($('#find-edit-items [data-dropzone-id]'), function( $item,index  ){

                            Utils.initDropzone('item-' + index);

                        });
                    }

                    var sortable = new Sortable($('#find-edit-items')[0], {
                        sort: true,
                        delay: 0,
                        disabled: false,
                        store: null,
                        animation: 150,
                        draggable: ".page-block-item",
                        handle: '.find-sort-item-handle',
                        scroll: true,
                        scrollSensitivity: 30,
                        scrollSpeed: 10,

                        onEnd: function (event) {
                            // _this.collection.updateOrder();
                            // Utils.alert({type: "info", message: "To-do: Save these after drop"});
                        }
                    });

                    $('#type-items').show();

                    if(type === 'custom') {
                        $('#type-custom').show();
                    }
    
                    return;

                case 'category':

                    // Get all Categories
                    var categoryCollection = new Collection(null, {
                        modelName: 'tags',
                        fetchOptions: {
                            data: {
                            }
                        }
                    });

                    categoryCollection.fetch({
                        data: {where: {type: 'category'}, pageSize: 50},
                        success: function(collection, response, options){
                            $('#find-edit-cateogries').empty().append(_this.selectCollectionTemplate({
                                models: _.sortBy(collection.models, function(obj) { return obj.attributes.name; }),
                                options: {
                                    id: 'category_id',
                                    activeModel: _this.model
                                }
                            }));
                        },
                        error: function(collection, response, options){
                            console.error('Error getting categories');
                            return false
                        }
                    });

                    $('#type-category').show();

                    return;

                case 'series':

                    // Get all Series
                    var seriesCollection = new Collection(null, {
                        modelName: 'series',
                        fetchOptions: {
                            data: {
                            }
                        }
                    });
                    seriesCollection.fetch({
                        data: {pageSize: 50},
                        success: function(collection, response, options){
                            $('#find-edit-series').empty().append(_this.selectCollectionTemplate({
                                models: _.sortBy(collection.models, function(obj) { return obj.attributes.name; }),
                                options: {
                                    id: 'series_id',
                                    activeModel: _this.model
                                }
                            }));
                        },
                        error: function(collection, response, options){
                            console.error('Error getting series');
                            return false
                        }
                    });

                    $('#type-series').show();

                    return;
                case 'history':
                    // No additional fields
                    return;
                case 'category_nav':
                    // No additional fields
                    return;
                default:
                    console.error('Not a valid block type');
                    return;

            }

        },

        changeType: function ( event ) {

            var type = $(event.target).val();

            // Hide inputs on change
            $('#type-items, #type-category, #type-series, #type-history, #type-custom').hide();

            this.showInputs(type);

        },

        addNewItem: function ( event ) {

            event.preventDefault();

            var tempID = Math.floor((1 + Math.random()) * 0x10000).toString(8);

            $('#find-edit-items').append(this.editPageBlockItemTemplate({item: {id:tempID }}))
                .ready( function () {
                    Utils.initDropzone('item-' + tempID);
                });
        },

        setImage: function ( event ) {

            var $input = $(event.currentTarget);
            var image = $input.val();

            var updatedID = $input.attr('data-updates');
            var $zone = $('[data-dropzone-id="' + updatedID + '"]');

            // Set the image on a non-dropzone input for control
            var $hiddenInput = $input.siblings('.image');
            $hiddenInput.val(image);

            $zone.attr('style', 'background-image: url("'+ $input.val() +'")');

        },

        deleteItem: function ( event ) {

            event.preventDefault();

            var $clicked = $(event.currentTarget);
            var item = $clicked.parents('.page-block-item');
            item.remove();
        },

        saveChanges: function (event) {

            event.preventDefault();

            var _this = this;

            var isNew = this.model.isNew();

            var $form = $(event.currentTarget);

            // Animate loading button
            var $button = $form.find('button[type="submit"]');
            Utils.buttonLoading($button);

            // Get form data
            var data = $form.serializeObject();

            var type = data.type;

            if(type !== "category" && data.category_id){
                data.category_id = null;
                data.category_display_grid = false;
            } else if (type !== "series" && data.series_id){
                data.series_id = null;
                data.series_display_grid = false;
            }

            if(type === "slider" || type === 'custom' || type === 'cta'){

                // Loop through items, make them a string, and set them on the model for storage in the DB
                var $items = $('.item-form');

                var itemArray = [];

                _.each($items, function (item, index) {

                    var elements = item.elements;

                    var blockItem = {
                        "image":        elements.image.value,
                        "title":        elements.title.value,
                        "subtitle":     elements.subtitle.value,
                        "buttonLink":   elements.buttonLink.value,
                        "buttonText":   elements.buttonText.value,
                    };

                    itemArray.push(blockItem);

                });
                
                data.block_items_array = itemArray;
            } else if(type === "category"){
                data.category_display_grid = $('#category_display_grid').is(":checked") ? true : false;
            } else if(type === "series"){
                data.series_display_grid = $('#series_display_grid').is(":checked") ? true : false;
            }

            if(type === 'custom') {
                data.custom_display_large = $('#custom_display_large').is(":checked") ? true : false;
            }

            this.model.save(data,{
                success: function( model, response, options ){

                    Utils.buttonReset($button);

                    _this.hideModal();

                    Utils.alert(_this.messages.blockSaved, 3000);

                    if(isNew){
                        _this.collection.add(model, {merge: true});
                    } else{
                        // Being explicit because we don't want the collection to have to listen to all 'change' events
                        _this.collection.trigger('change:block');
                    }

                },
                error: function(model, response, options) {
                    Utils.alert(_this.messages.blockSavedError, 4000);
                    console.error(response);
                }
            });

        },

        hideModal: function () {
            this.$singleModal.modal('hide');
        },

        closedModal: function () {
            // Unbind page block events when we're done with the modal.
            this.$view.empty();
            this.$el.off('submit', '#form-page-block');
            this.$el.off('change', '#type');
            this.$el.off('click', '#add-new-item');
            this.$el.off('click', '.item-delete');
            this.$el.off('change', '.trigger-dropzone-update');
        }
    });
});

