/**
 * @desc App View
 *
 *
 */
define('views/edit-tags',[

    'jquery',
    'underscore',
    'underscore.string',
    'backbone',
    'utils',
    'models/core',
    'collections/core',
    'text!templates/edit-tags.html'

],	function($, _, _string, Backbone, Utils, Model, Collection, EditTagTemplate){

    'use strict';

    return Backbone.View.extend({

        // setup DOM Elements
        el : $('body'),
        editTagTemplate: _.template(EditTagTemplate),

        // bind Events
        events: {
            'keyup .trigger-search-tag' : 'searchTags',
            'click .trigger-add-tag' : 'addTag',
            'click .trigger-select-tag' : 'selectTag',
            'click .trigger-remove-tag' : 'removeTag'
        },

        /**
         * initialize()
         * @desc initialize the view
         *
         * @param options
         */
        initialize: function(options){

            var _this = this;

            console.log('Edit Tags view...');

            this.viewType = options.viewType;
            this.setElement( options.$view );
            this.modelName = options.modelName ? options.modelName : 'video_tags';
            this.video = options.video;
            this.series = options.series;
            console.log(_this.viewType)

            this.messages = Utils.getConfig().messages;
            this.tagCollection = new Collection(null, {
                modelName: 'tags',
                fetchOptions: {
                    data: {
                        where: {type: _this.viewType}
                    }
                }
            });
            console.log(this.tagCollection)

            this.collection = new Collection(options.tags, {modelName: this.modelName});

            this.tagCollection.on('sync', this.renderSearchResults, this);
            this.collection.on('update', this.render, this);

            this.render();

        },

        /**
         * render()
         * @desc Render the view
         *
         *
         */
        render: function(){

            this.$el.empty().append(this.editTagTemplate({
                viewType: this.viewType,
                tags : this.collection.toJSON()
            }));

            this.$searchResults = $('#find-tag-results');

        },

        /**
         * searchTags()
         * @desc
         *
         * @param event
         *
         */
        searchTags: function(event){

            event.preventDefault();

            var _this = this;

            console.log('Search...');

            var $input = $(event.currentTarget);

            _this.$searchResults.empty().append('<li class="list-group-item color--gray-darkest text-xs-center font--sm-2 p-y-2"><span class="opacity--5"><span class="fa fa-spinner fa-pulse"></span><span class="m-l-quarter">Loading...</span></span></li>');

            Utils.delay(function(){

                var searchTerm = $input.val();

                console.log('Searching for...' + searchTerm);

                if(!searchTerm){ return; }

                _this.tagCollection.updateFetchOptions({
                    data: {
                        searchKeys : 'name',
                        searchTerm: searchTerm,
                    }
                });
                _this.tagCollection.fetchPage();


            }, 250);


        },


        /**
         * @desc render search results
         */
        renderSearchResults: function(){

            var _this = this

            this.$searchResults.empty();

            _.each(this.tagCollection.toJSON(), function(tag){

                _this.$searchResults.append('<li data-tag-id="' + tag.id + '" class="list-group-item color--gray-darkest cursor--pointer trigger-select-tag">' + tag.name + '</li>');

            });

            if(!this.tagCollection.length){
                _this.$searchResults.empty().append('<li class="list-group-item color--gray-darkest text-xs-center font--sm-2 p-y-2"><span class="fa fa-warning"></span><span class="m-l-quarter">Nothing found. Try again.</span></li>');
            }

        },

        /**
         * @desc
         *
         * @param event
         *
         */
        selectTag : function( event ){

            var _this = this;

            event.preventDefault();

            var $clicked = $(event.currentTarget);

            var tagId = $clicked.attr('data-tag-id');

            var itemTag = new Model({
                tag_id: tagId
            }, {modelName: this.modelName});

            if(this.series){
                itemTag.set({series_id: this.series.id});
            } else {
                itemTag.set({video_id: this.video.id});
            }

            Utils.buttonLoading($clicked);

            itemTag.save({}, {
                success: function(model, response, options){
                    Utils.buttonReset($clicked);
                    Utils.alert(_this.messages.tagsSaved);
                    itemTag.set({
                        tag : _this.tagCollection.get(tagId).toJSON()
                    });
                    _this.collection.add(itemTag, {merge: true});
                }
            });

        },

        /**
         * removeTag()
         * @desc
         *
         * @param event
         *
         */
        removeTag: function( event ){

            var _this = this;

            event.preventDefault();

            var $clicked = $(event.currentTarget);

            var id = $clicked.attr('data-item-id');

            var model = this.collection.get(id);

            _this.collection.remove(model , {merge: true});

            model.destroy({success: function(){
                Utils.alert(_this.messages.tagRemoved);
            }, error: function(){
                _this.collection.add(model , {merge: true});
            }});

        },

        /**
         * addTag()
         * @desc
         *
         * @param event
         *
         */
        addTag: function( event ){

            var _this = this;

            event.preventDefault();

            var $clicked = $(event.currentTarget);

            var $name = this.$el.find('input[name="new_tag"]');
            var name = $name.val();

            if(!name){
                $name.focus();
                Utils.alert(_this.messages.requiredTagName);
                return;
            }

            Utils.buttonLoading($clicked);

            var newTag = new Model(null, {modelName: 'tags'});

            newTag.save({
                name: name,
                type: this.viewType,
                slug: _string.slugify(name)
            }, {success: function(model, res, options){

                _this.tagCollection.add(newTag, {merge: true});

                var itemTag = new Model(null, {modelName: _this.modelName});

                if(_this.series){
                    itemTag.set({series_id: _this.series.id, tag_id: newTag.get('id')});
                } else {
                    itemTag.set({video_id: _this.video.id, tag_id: newTag.get('id')});
                }

                itemTag.save({}, {
                    success: function(){
                        Utils.buttonReset($clicked);
                        itemTag.set({
                            tag : newTag.toJSON()
                        });
                        _this.collection.add(itemTag, {merge: true});
                    }
                });

            }});
        },



    });

});
