/**
 * @desc App View
 *
 *
 */
define('views/edit-files',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'models/core',
	'collections/core',
	'text!templates/edit-files.html',
	'sortable'

],	function($, _, Backbone, Utils, FileModel, FileCollection, EditFileTemplate, Sortable){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('#find-edit-files'),
		editFileTemplate: _.template(EditFileTemplate),
		
		// bind Events
		events: {
			'keyup .trigger-save-file' : 'saveFile',
			'click .trigger-add-file' : 'addFile',
			'click .trigger-remove-file' : 'removeFile'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			console.log('Edit Files view...');

			this.messages = Utils.getConfig().messages;
			
			this.fileCollection = new FileCollection(options.files, {modelName: 'files', sortHandle: '.find-sort-file'});
			this.video = options.video;

			this.fileCollection.on('update', this.render, this);
			this.fileCollection.on('sort', this.render, this);
			
			this.render();

		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){
			
			var _this = this;

			this.$el.empty().append(this.editFileTemplate({
				files : this.fileCollection.toJSON()
			}));

			var sortable = new Sortable(this.$el[0], {
				sort: true,
				delay: 0,
				disabled: false,
				store: null,
				animation: 150,
				draggable: ".find-sort-file",
				handle: '.find-sort-file-handle',
				scroll: true,
				scrollSensitivity: 30,
				scrollSpeed: 10,

				onEnd: function (event) {
					_this.fileCollection.updateOrder();
					Utils.alert(Utils.getConfig().messages.fileSaved);
				}
			});
			
		},

		/**
		 * addFile()
		 * @desc
		 *
		 * @param event
		 *
		 */
		addFile: function( event ){
			event.preventDefault();

			var _this = this;

			var $clicked = $(event.currentTarget);

			Utils.buttonLoading($clicked);
			
			var model = new FileModel(null, {modelName: 'files'});
			
			model.save({
				video_id: this.video.id, 
				order: _this.fileCollection.length
			},{
				success: function(){
					_this.fileCollection.add(model, {merge: true});
					Utils.buttonReset($clicked);
				}
			});
			
		},

		/**
		 * searchTags()
		 * @desc
		 *
		 * @param event
		 *
		 */
		saveFile: function(event){

			event.preventDefault();

			var _this = this;

			console.log('Save...');

			var $input = $(event.currentTarget);

			Utils.delay(function(){

				var id = $input.attr('data-file-id');
				var value = $input.val();
				var key = $input.attr('name');

				console.log('Saving for...' + value);
				
				var model = _this.fileCollection.get(id);
				
				model.set(key, value);
				model.save({}, {
					success: function(){
						Utils.alert(_this.messages.fileSaved);
					}
				});


			}, 500);


		},

		/**
		 * removeFile()
		 * @desc
		 *
		 * @param event
		 *
		 */
		removeFile: function( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);

			var id = $clicked.attr('data-file-id');

			var model = this.fileCollection.get(id);

			_this.fileCollection.remove(model , {merge: true});

			model.destroy({success: function(){
				Utils.alert(_this.messages.fileRemoved);
				_this.fileCollection.updateOrder();
			}, error: function(){
				_this.fileCollection.add(model , {merge: true});
			}});

		},


	});

});
