/**
 * @desc App View
 *
 *
 */
define('views/account-setup',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'models/core'

],	function($, _, Backbone, Utils, Model){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),

		// bind Events
		events: {
			'click .trigger-save-account-setup': 'saveAccount',
			'change #role' : 'toggleRole'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			console.log('----Account Setup---');
			this.messages = Utils.getConfig().messages;
			this.user = new Model(Utils.getData('user'), {modelName: 'users'});
			console.log(this.user);
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			// No render

		},

		/**
		 *
		 * scrollTo()
		 *
		 * @param event
		 */
		saveAccount: function ( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);
			var $form = $('#form-account-setup');

			var $firstName = $('input[name="first_name"]');
			var $lastName = $('input[name="last_name"]');
			
			if(!$firstName.val()){
				Utils.alert(this.messages.requiredAll);
				$firstName.focus();
				return;
			}
			if(!$lastName.val()){
				Utils.alert(this.messages.requiredAll);
				$lastName.focus();
				return;
			}

			Utils.buttonLoading($clicked);

			var data = $form.serializeObject();
			
			this.user.save(data, {
				success: function(model, response, options){
					
					if(response.status === 'error'){
						Utils.alert(response.message);
						Utils.buttonReset($clicked);
						return;
					}
					Utils.alert(_this.messages.accountSaved);
					window.location = '/';
				}
			});

		},


		toggleRole: function( event ) {
			
			
			$('.toggle-role').hide();
			$('#' + event.target.value.toLowerCase()).fadeIn();
			$('.trigger-save-account-setup').attr('disabled', false);
			
		}

	});

});
