/**
 * @desc App View
 *
 *
 */
define('views/admin-series-single',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'underscore.string',
	'models/core',
	'views/edit-series-videos',
	'views/edit-tags'

],	function($, _, Backbone, Utils, _string, Model, SeriesContentView, EditTagsView){

	'use strict';
	console.log(Utils)

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),

		// bind Events
		events: {
			'click .trigger-save-series': 'save',
			'click .trigger-delete-series' : 'delete',
			'keyup #name' : 'generateSlug',
			'keyup #slug' : 'generateSlug',
			'change .trigger-dropzone-update' : 'setImage',
			'click .trigger-remove-image' : 'removeImage'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			console.log('---Series Single View---');

			this.messages = Utils.getConfig().messages;
			this.user = Utils.getData('user');

			this.model = new Model(Utils.getData('series'), {modelName: 'series'});
			this.is_new = this.model.isNew();

			Utils.initDropzone('image');

			this.seriesContentView = new SeriesContentView({
                seriesVideos : this.model.get('videos'),
                series: Utils.getData('series')
            });

			this.editTagView = new EditTagsView({
				viewType: 'tag',
				modelName : 'series_tags',
				$view : $('#find-edit-tags'),
				tags: _.filter(this.model.get('series_tags'), function(i){return i.tag.type === 'tag'}),
				series: this.model.toJSON()
			});

			this.editCategoryView = new EditTagsView({
				modelName : 'series_tags',
				viewType: 'category',
				$view : $('#find-edit-cateogries'),
				tags: _.filter(this.model.get('series_tags'), function(i){return i.tag.type === 'category'}),
				series: this.model.toJSON()
			});

		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			// No render

		},

		/**
		 *
		 * @desc save series
		 *
		 * @param event
		 */
		save: function ( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);
			var $form = $('#form-series');

			var $name = $('input[name="name"]');
			var name = $name.val();
			var $slug = $('input[name="slug"]');
			var slug = $slug.val();

			if(!name){
				Utils.alert(this.messages.requiredTitle);
				$name.focus();
				return;
			}

			if(!slug){
				Utils.alert(this.messages.requiredSlug);
				$slug.focus();
				return;
			}

			Utils.buttonLoading($clicked);
			this.model.$button = $clicked;

			var data = $form.serializeObject();

			this.model.set(data);

			this.model.save({}, {
				success: function(){
					Utils.alert(_this.messages.seriesSaved);
					if(_this.is_new ){
						window.location = '/admin/series/' + _this.model.get('id');
					} else {
						Utils.buttonReset($clicked);
					}
				}
			});

		},

		delete : function( event ) {

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);

			Utils.buttonLoading($clicked);

			var check = window.confirm('Are you sure you want to delete this series?');

			if(!check){
				Utils.buttonReset($clicked);
				return;
			}

			this.model.destroy({success: function(){
				Utils.alert(_this.messages.seriesDeleted);
				window.location = '/admin/series';
			}});

		},


		/**
		 *
		 * setImage
		 *
		 * @param event
		 */
		setImage: function( event ){

			var _this = this;

			var $input = $(event.currentTarget);

			var image = $input.val();

			$('#' + $input.attr('data-updates')).css('background-image', "url(" + image + ")");

			var obj = {};

			obj[$input.attr('data-updates')] = $input.attr('data-file-name');

			this.model.set(obj);

			$('.trigger-remove-image').show();

		},


		/**
		 *
		 * removeImage
		 *
		 * @param event
		 */
		removeImage: function( event ){

			var _this = this;

			event.preventDefault();

			var data = {};

			var $button = $(event.currentTarget);

			data[$button.attr('data-remove')] = '';

			this.model.set(data);

			$button.hide();

			$('#' + $button.attr('data-remove')).css('background-image', "url()");

		},



		generateSlug: function( event ){

			event.preventDefault();

			var _this = this;

			Utils.delay(function(){

				if(_this.is_new || $(event.currentTarget).attr('id') === 'slug'){
					var title = $(event.currentTarget).val();
					var slug = _string.slugify(title);
					_this.model.set({
						slug: slug
					});
					$('#slug').val(slug);

				}

			}, 500);

		}


	});

});
