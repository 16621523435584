/**
 * @desc Single Modal View
 *
 *
 */
define('views/modal-single',[

    'jquery',
    'underscore',
    'underscore.string',
    'backbone',
    'utils',
    'sortable',
    'models/core',
    'collections/core'
],	function($, _, _string, Backbone, Utils, Sortable, Model, Collection){

    'use strict';

    return Backbone.View.extend({

        // setup DOM Elements
        el : $('#modal-single'),
        $view: $('#modal-single-body'),

        // bind Events
        events: {
            'hidden.bs.modal #modal-single': 'closedModal',
            'shown.bs.modal #modal-single': 'shownModal'
        },

        /**
         * initialize()
         * @desc initialize the view
         *
         * @param options
         */
        initialize: function(options){

            var _this = this;

            console.log('Initializing Single Modal view...');

            // Secretly initialize the bootstrap modal
            $('#modal-single').modal({ show: false});

            this.messages = Utils.getConfig().messages;

            this.render();
        },

        /**
         * render()
         * @desc Render the view
         *
         *
         */
        render: function(){

            // This view handles logic, no template or things to render.

        },

        closedModal: function () {

            this.trigger('modal:closed');

        },

        shownModal: function () {

            this.trigger('modal:shown');

        }

    });

});
