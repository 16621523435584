/**
 * @desc Auth View
 *
 *
 */
define('views/auth',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'models/core'

],	function($, _, Backbone, Utils, Model){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),

		// bind Events
		events: {
			'click .trigger-signup' : 'signup',
			'click .trigger-login' : 'login',
			'click .trigger-request-reset' : 'requestReset',
			'click .trigger-submit-reset' : 'submitReset'
		},

		/**
		 * initialize()
		 * @desc intialize the view
		 *
		 *
		 */
		initialize: function(){
			console.log('----Auth View----');

			$('.trigger-scroll-to').on('click', function ( event ) {
                Utils.scrollTo( event );
            });

			this.render();
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			// Find bootstrap popovers and initialize them
            $("[data-toggle=popover]").popover({
                template: '<div class="popover" role="tooltip"><div class="popover-arrow"></div><h3 class="popover-title color--gray-dark"></h3><div class="popover-content p-a-1 color--gray-dark"></div></div>'
			});

		},

		/**
		 * signup()
		 *
		 *
		 * @param event
		 */
		signup: function(event) {

			event.preventDefault();

			var $button = $(event.currentTarget);

			var $form = $button.parents('form');
			var redirect = $form.attr('data-redirect') ? $form.attr('data-redirect') : '/account';

			var $email = $form.find('input[name="email"]');
			var $password = $form.find('input[name="password"]');
			var messages = Utils.getConfig().messages;

			var email = $email.val().toLowerCase();
			var password = $password.val();
			var confirmPassword = $form.find('input[name="confirm_password"]').val();
			var emailUpdates = $form.find('input[name="email_updates"]').is(':checked');


			// email required
			if(!email){
				Utils.alert( messages.requiredEmail );
				$email.focus();
				return;
			}

			// valid email required
			if(!Utils.validateEmail(email)){
				Utils.alert( messages.invalidEmail );
				$email.focus();
				return;
			}

			// valid password strength
			if( !Utils.validatePassword( password , confirmPassword) ){
				$password.focus();
				return;
			}

			this.user = new Model(null, {modelName: 'users'});

			Utils.buttonLoading($button);

			this.user.save( {
				email : email,
				password: password,
				email_updates: emailUpdates
			} , {
				success: function(model, response, options) {

					if(response.status === 'fail'){

						Utils.buttonReset($button);
						Utils.alert(response.data, 10000);
						return;

					}

					Utils.trackSegment('Sign Up', {
						user_id: model.get('id'),
						email: model.get('email'),
						category: 'Account',
						label: 'User',
						value: 1
					});

					setTimeout(function(){
						window.location = '/account/setup';
					}, 250);



				}
			});
		},

		/**
		 * login()
		 *
		 *
		 * @param event
		 */
		login: function(event) {

			event.preventDefault();

			var $button = $(event.currentTarget);

			var form = $('#login');

			var redirect = form.attr('data-redirect') ? form.attr('data-redirect') : '/account';

			var $email = $('input[name="email"]');
			var $password = $('input[name="password"]');
			var messages = Utils.getConfig().messages;

			var email = $email.val().toLowerCase();
			var password = $password.val();

			// email required
			if(!email){
				Utils.alert( messages.requiredEmail );
				$email.focus();
				return;
			}

			// password required
			if(!password){
				Utils.alert( messages.requiredPassword );
				$password.focus();
				return;
			}

			Utils.buttonLoading($button);

			$.post(form.attr('action'), form.serialize(), function(response) {

				Utils.buttonReset($button);

				if(response.status === 'error') {
					Utils.alert(response);
				} else {
					window.location = redirect;
				}

			}, 'json');

			return false;

		},

		/**
		 *
		 * requestReset
		 *
		 * @param event
		 * @returns {boolean}
		 */
		requestReset: function(event) {

			event.preventDefault();

			var $button = $(event.currentTarget);

			var form = $('#request-reset');

			var email = $('input[name="email"]').val().toLowerCase();

			var messages = Utils.getConfig().messages;

			// email required
			if(!email){
				Utils.alert(messages.requiredEmail);
				return;
			}

			if(!Utils.validateEmail(email)){
				Utils.alert(messages.invalidEmail);
				return;
			}

			Utils.buttonLoading($button);

			$.post(form.attr('action'), {
				email: email
			}, function(response) {

				Utils.buttonReset($button);

				if(response.status === 'error') {

					Utils.alert(response);

				} else {

					Utils.alert(messages.resetSuccess);

				}

			}, 'json');

			return false;

		},

		/**
		 *
		 * submitReset
		 *
		 * @param event
		 * @returns {boolean}
		 */
		submitReset: function(event) {

			event.preventDefault();

			var $button = $(event.currentTarget);

			var form = $('#submit-reset');
			var password = $('input[name="password"]').val();
			var passwordConfirm = $('input[name="password_confirm"]').val();

			if( !Utils.validatePassword(password, passwordConfirm) ){
				return;
			}

			Utils.buttonLoading($button);

			$.post(form.attr('action'), form.serialize(), function(response) {

				Utils.buttonReset($button);

				if(response.status === 'error') {

					Utils.alert(response.message);

				} else {

					window.location = "/account";

				}

			}, 'json');

			return false;

		},


	});

});
