/**
 * @desc App View
 *
 *
 */
define('views/admin-single',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'underscore.string',
	'models/core',
	'views/edit-tags',
	'views/edit-files'

],	function($, _, Backbone, Utils, _string, Model, EditTagsView, EditFileView){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),

		// bind Events
		events: {
			'click .trigger-save': 'save',
			'click .trigger-change-password' : 'changePassword',
			'change .trigger-dropzone-update' : 'setImage',
			'click .trigger-remove-image' : 'removeImage',
			'click .trigger-delete' : 'delete',
			'keyup #title' : 'generateSlug',
			'keyup #slug' : 'generateSlug'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){
			
			console.log('---Edit Single View---');
			
			this.messages = Utils.getConfig().messages;
			this.user = new Model(Utils.getData('user'), {modelName: 'users'});
			
			this.video = new Model(Utils.getData('video'), {modelName: 'videos'});
			this.is_new = this.video.isNew();
			console.log(this.video.toJSON());

			Utils.initDropzone('video_image');
			
			Utils.initDropzone('video_image_hover');
			
			this.editTagView = new EditTagsView({
				viewType: 'tag',
				$view : $('#find-edit-tags'),
				tags: _.filter(this.video.get('video_tags'), function(videoTag){return videoTag.tag.type === 'tag'}),
				video: this.video.toJSON()
			});

			this.editCategoryView = new EditTagsView({
				viewType: 'category',
				$view : $('#find-edit-cateogries'),
				tags: _.filter(this.video.get('video_tags'), function(videoTag){return videoTag.tag.type === 'category'}),
				video: this.video.toJSON()
			});

			this.editFileView = new EditFileView({
				files: this.video.get('files'),
				video: this.video.toJSON()
			});
			
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			// No render

		},

		/**
		 *
		 * scrollTo()
		 *
		 * @param event
		 */
		save: function ( event ){
			
			var _this = this;
			
			event.preventDefault();
			
			var $clicked = $(event.currentTarget);
			var $form = $('#form-video');
			
			var $title = $('input[name="title"]');
			var title = $title.val();
			var $slug = $('input[name="slug"]');
			var slug = $slug.val();
			
			if(!title){
				Utils.alert(this.messages.requiredTitle);
				$title.focus();
				return;
			}

			if(!slug){
				Utils.alert(this.messages.requiredSlug);
				$title.focus();
				return;
			}
			
			Utils.buttonLoading($clicked);
			
			var data = $form.serializeObject();
			if(!data.active){
				data.active = false;
			}
			if(!data.difficulty){
				data.difficulty = Number(data.difficulty);
			}
			if(!data.featured_order){
				data.featured_order = null;
			}
			
			this.video.set(data);
			
			var hasChanged = _this.video.hasChanged('slug');
			
			this.video.save().always(function(response){
				if(response.status === 'error'){
					Utils.alert(response.message);
					return;
				}
				Utils.alert(_this.messages.videoSaved);
				if(_this.is_new || hasChanged){
					window.location = '/admin/video/' + _this.video.get('slug');
				} else {
					Utils.buttonReset($clicked);
				}
				
				
			});
			
		},

		delete : function( event ) {

			var _this = this;

			event.preventDefault();
			
			var $clicked = $(event.currentTarget);
			
			Utils.buttonLoading($clicked);
			
			var check = window.confirm('Are you sure you want to delete this content?')
			
			if(!check){
				Utils.buttonReset($clicked);
				return;
			}
			
			this.video.save({deleted: true}).always(function(response){
				if(response.status === 'error'){
					Utils.alert(response.message);
					return;
				}
				Utils.alert(_this.messages.videoDeleted);
				window.location = '/admin';
				
			});
			
		},


		/**
		 *
		 * setImage
		 *
		 * @param event
		 */
		setImage: function( event ){

			var _this = this;

			var $input = $(event.currentTarget);

			var image = $input.val();
			
			$('#' + $input.attr('data-updates')).css('background-image', "url(" + image + ")");
			
			var obj = {};
			
			obj[$input.attr('data-updates')] = $input.attr('data-file-name');
			
			this.video.set(obj);

		},


		/**
		 *
		 * removeImage
		 *
		 * @param event
		 */
		removeImage: function( event ){

			var _this = this;

			event.preventDefault();
			
			var data = {};

			var $button = $(event.currentTarget);
			
			data[$button.attr('data-remove')] = '';

			this.video.set(data);

			$button.hide();

			$('#' + $button.attr('data-remove')).css('background-image', "url()");

		},


		generateSlug: function( event ){

			event.preventDefault();
			
			var _this = this;
			
			Utils.delay(function(){
				
				if(_this.is_new || $(event.currentTarget).attr('id') === 'slug'){
					var title = $(event.currentTarget).val();
					var slug = _string.slugify(title);
					_this.video.set({
						slug: slug
					});
					$('#slug').val(slug);
					
				}
				
			}, 500);
			
		}
		

	});

});
