define('views/multi_series',[
	'jquery',
	'underscore',
	'backbone',
	'utils',
	'underscore.string',
	'models/core',
	'collections/core',
	'videojs-global',
	'videojs-youtube',
	'text!templates/multi-series.html'

],	function($, _, Backbone, Utils, _string, Model, Collection, videojs, VideoJsYT, Template){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),
		template: _.template(Template),

		// bind Events
		events: {},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			var _this = this;

			console.log('---Multiple Series View---');
			this.admin = options.admin;
			this.$view = $('#find-series');
			this.series = options.series ? options.series : Utils.getData('series');
			this.messages = Utils.getConfig().messages;
			this.user = Utils.getData('user');


			var category_video = $('#category-video');
			var video_url = category_video.data('video');
			
			if(category_video.length > 0 && video_url != '') {
				
				this.videoPlayer = videojs('category-video', {
					controls: false,
					techOrder: ["youtube"],
					sources: [{
						type: "video/youtube",
						src: video_url
					}],
					youtube: {
						"ytControls": 2,
						"showinfo": 0,
						"rel": 0,
						"playsinline": 1,
						"modestbranding": 1
					}
				});

				// Remove controls from the player on iPad to stop native controls from stealing
				// our click
				var contentPlayer =  document.getElementById('video--custom');
				if ((navigator.userAgent.match(/iPad/i) ||
					navigator.userAgent.match(/Android/i)) &&
					contentPlayer.hasAttribute('controls')) {
					contentPlayer.removeAttribute('controls');
				}

				// Initialize the ad container when the video player is clicked, but only the
				// first time it's clicked.
				var startEvent = 'click';
				if (navigator.userAgent.match(/iPhone/i) ||
					navigator.userAgent.match(/iPad/i) ||
					navigator.userAgent.match(/Android/i)) {
					startEvent = 'touchend';
				}


				this.videoPlayer.one(startEvent, function() {
					_this.videoPlayer.play();
				});
			}

			// this.collection = new Collection(this.series, {
			// 	modelName: 'series',
			// 	pagination: Utils.getData('pagination'),
			// 	fetchOptions: Utils.getData('paginationOptions')
			// });

			// this.listenTo(this.collection, 'sync', this.render);

			Utils.viewLoading(this.$view);

			this.render();
		},

		/**
		 * @desc Render the view
		 */
		render: function(){

			var template = this.template({
				collection: this.series,
				_string: _string,
				admin: this.admin,
				utils: Utils
			});

			this.$view.empty().append(template);

			Utils.viewReset(this.$view);

		}


	});

});
