/**
 * @desc Initialize the App
 *
 *
 */
define('app',[
	'jquery',
	'underscore',
	'backbone',
	'bootstrap',
	'utils',
	'config',
	'router',
	'videojs',
	'videojs-youtube',
	'waypoints',

],	function($, _, Backbone, Bootstrap, Utils, config, Router, v, vy, Waypoints){

	var initialize = function(){

    // Sentry.init({
    //   dsn: "https://f4a5fb940aeb4a8994bcd9ba5ea915a2@o1165204.ingest.sentry.io/6258087",
    //   integrations: [new SentryTracing()],    
    //   tracesSampleRate: 0.25,
    // });

    if($('.hero-video').length) {
      console.log('hero video found');
      $('.hero-video').on('click', function(){
        var $video = $(this).find('.video');
        var src = $video.data('src');

        $video.html('<iframe src="'+src+'" width="1280", height="720", frameborder="0", allow="autoplay; fullscreen", allowfullscreen />');
      })
    }

		if($('#main-nav-waypoint').length) {
			new Waypoint({
				element: document.getElementById('main-nav-waypoint'),
				handler: function(direction) {
					if (direction === 'up') {
						$('.navbar-main-nav').removeClass('fixed-mobile');
					}
	
					if(direction === 'down') {
						$('.navbar-main-nav').addClass('fixed-mobile');
					}
				}
			})
		}

		/**
		 * Append token to all ajax requests
		 */
		$.ajaxPrefilter(function (options, originalOptions, jqXHR) {

			if (config != null) {
				if (options.url.search("/api/") != -1) {
					options.url = options.url + '?token=' +  config.token;
				}
			}

		});

		Router.initialize();

	};

	return {

	initialize: initialize

	};

});

