/**
 * @desc App View
 *
 *
 */
define('views/account',[

	'jquery',
	'underscore',
	'underscore.string',
	'moment',
	'backbone',
	'utils',
	'models/core',
	'collections/core',
	'text!../templates/watched-history-list.html',
	'text!../templates/no-watch-history-yet.html'

],	function($, _, _string, moment, Backbone, Utils, Model, WatchHistoryCollection, WatchedHistoryListTemplate, NoWatchHistoryYetTemplate){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),
		$view : $('#find-view-watched-history-list'),
		$viewHistoryItems: $('#history-items'),
		$showWatchHistoryHere: $('#show-watch-history-here'),
		WatchedHistoryListTemplate: WatchedHistoryListTemplate,

		// bind Events
		events: {
			'click .trigger-save-account': 'saveAccount',
			'click .trigger-change-password' : 'changePassword',
			'change #role' : 'toggleRole',
			'click .trigger-delete-account': 'deleteAccount'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			var _this = this;

			console.log('----Account---');
			this.messages = Utils.getConfig().messages;
			this.config = Utils.getConfig();
			this.user = new Model(Utils.getData('user'), {modelName: 'users'});
      
			if(options.$view){
				this.$view = options.$view;
			}

			Utils.viewLoading(this.$view);

			this.watchHistoryCollection = new WatchHistoryCollection(Utils.getData("watchHistory"), {
				modelName: "watch_history",
				pagination: Utils.getData("pagination"),
				fetchOptions: Utils.getData("fetchOptions")
			});

			this.listenTo(this.watchHistoryCollection, 'sync', this.render);
			this.listenTo(this.watchHistoryCollection, 'request', function(){

				if(_this.watchHistoryCollection.fetchOptions.reset){
					Utils.viewLoading(_this.$view);
				}

			});

			if(!this.watchHistoryCollection.length){
				this.watchHistoryCollection.fetchPage();
			} else {
				this.render();
			}
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			var _this = this;
			var accountHistoryConfig = _this.config;
			_this.watchHistoryLength = this.watchHistoryCollection.toJSON().length;

			var noWatchHistoryYetTemplate = _.template(NoWatchHistoryYetTemplate);
			var noWatchHistoryYetHTML = noWatchHistoryYetTemplate({ _string: _string });
			if(_this.watchHistoryLength === 0){
				this.$showWatchHistoryHere.empty().append(noWatchHistoryYetHTML);
			}

			var template = _.template(WatchedHistoryListTemplate);
	    var historyHTML = template({histories: this.watchHistoryCollection.toJSON(), _string: _string, moment: moment, config: accountHistoryConfig});
			this.$view.empty().append(historyHTML);

			if(_this.watchHistoryLength === 1){
				this.$viewHistoryItems.empty().append(_this.watchHistoryLength + ' Item');
			} else {
				this.$viewHistoryItems.empty().append(_this.watchHistoryLength + ' Items');
			}
			Utils.viewReset(this.$view, this.$viewHistoryItems);
		},

		/**
		 *
		 * scrollTo()
		 *
		 * @param event
		 */
		saveAccount: function ( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);
			var $form = $('#form-account');

			var $email = $('input[name="email"]');
			var email = $email.val();

			if(!Utils.validateEmail(email)){
				Utils.alert(this.messages.requiredEmail);
				$email.focus();
				return;
			}

			Utils.buttonLoading($clicked);

			var data = $form.serializeObject();
			if(!data.email_updates){
				data.email_updates = false;
			}
			this.user.save(data, {
				success: function(model, response, options){
					Utils.buttonReset($clicked);
					if(response.status === 'error'){
						Utils.alert(response.message);
						return;
					}
					Utils.alert(_this.messages.accountSaved);
				}
			});

		},

		/**
		 * 
		 * @param event
		 */
		deleteAccount: function ( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);
			var $form = $('#form-account');

			Utils.buttonLoading($clicked);
			
			var confirmCancel = confirm("Are you sure you want to delete your account?");
			
			if ( confirmCancel ){
				this.user.destroy({
				 success: function(model, response, options){
					 Utils.buttonReset($clicked);
					 window.location = '/account/goodbye';
				 }
			  });
			}

		},
		/**
		 *
		 * scrollTo()
		 *
		 * @param event
		 */
		changePassword: function ( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);

			var $newPassword = $('input[name="new_password"]');
			var newPassword = $newPassword.val();
			var $confirm = $('input[name="confirm_password"]');
			var confirm = $confirm.val();

			if(!Utils.validatePassword(newPassword, confirm)){
				$newPassword.focus();
				return;
			}

			Utils.buttonLoading($clicked);

			this.user.save({password: newPassword}, {
				success: function(model, response, options){
					Utils.buttonReset($clicked);
					if(response.status === 'error'){
						Utils.alert(response.message);
						return;
					}
					$newPassword.val('');
					$confirm.val('');
					Utils.alert(_this.messages.passwordUpdated);
				}
			});

		},

		toggleRole: function( event ) {


			$('.toggle-role').hide();
			$('#' + event.target.value.toLowerCase()).fadeIn();

		}


	});

});

