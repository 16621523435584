/**
 * @desc App View
 *
 *
 */
define('views/edit-taxonomy-series',[

    'jquery',
    'underscore',
    'underscore.string',
    'backbone',
    'utils',
    'models/core',
    'collections/core',
    'text!templates/edit-series-cards.html',
    'sortable'

],	function($, _, _string, Backbone, Utils, Model, Collection, Template, Sortable){

    'use strict';

    return Backbone.View.extend({

        // setup DOM Elements
        el : $('#find-edit-tax-series'),
        template: _.template(Template),

        // bind Events
        events: {
            // 'click .trigger-select-content' : 'selectContent',
            // 'keyup .trigger-search-content' : 'searchContent',
            // 'click .trigger-remove-content' : 'removeContent'
        },

        /**
         * initialize()
         * @desc initialize the view
         *
         * @param options
         */
        initialize: function(options){

            console.log('Edit Taxonomy Series view...');

            this.messages = Utils.getConfig().messages;

            this.collection = new Collection(options.series, {modelName: 'series_tags', sortHandle: '.find-sort-content-series'});
            this.category = options.category;

            // this.videoCollection = new Collection(null, {modelName: 'videos'});
            // this.videoCollection.on('sync', this.renderSearchResults, this);

            this.collection.on('update', this.render, this);
            this.collection.on('sort', this.render, this);

            this.render();

        },

        /**
         * render()
         * @desc Render the view
         *
         *
         */
        render: function(){

            var _this = this;

            this.$el.empty().append(this.template({
                collection : this.collection.toJSON(),
                admin: true,
                _string: _string,
                utils: Utils
            }));

            var sortable = new Sortable(this.$el.find('#sortable-content-series')[0], {
                sort: true,
                delay: 0,
                disabled: false,
                store: null,
                animation: 150,
                draggable: ".find-sort-content-series",
                handle: '.find-sort-content-handle',
                scroll: true,
                scrollSensitivity: 30,
                scrollSpeed: 10,

                onEnd: function (event) {
                    _this.collection.updateOrder();
                    Utils.alert(Utils.getConfig().messages.order);
                }
            });

            this.$searchResults = $('#find-video-results');

        },

        // /**
        //  * @desc
        //  *
        //  * @param event
        //  *
        //  */
        // selectContent : function( event ){
        //
        // 	var _this = this;
        //
        // 	event.preventDefault();
        //
        // 	var $clicked = $(event.currentTarget);
        //
        // 	var id = $clicked.attr('data-id');
        //
        // 	var model = new Model({
        // 		category_id: this.category.id,
        // 		video_id: id,
        // 		order: this.collection.length,
        // 		video: _this.videoCollection.get(id).toJSON()
        // 	}, {modelName: 'series_videos'});
        //
        // 	_this.collection.add(model, {merge: true});
        //
        // 	model.save({}, {
        // 		success: function(model, response, options) {
        // 			Utils.alert(_this.messages.seriesSaved);
        // 		}
        // 	});
        //
        // },

        /**
         * @desc
         *
         * @param event
         *
         */
        removeContent: function( event ){

            var _this = this;

            event.preventDefault();

            var $clicked = $(event.currentTarget);

            var id = $clicked.attr('data-id');

            var model = this.collection.get(id);

            _this.collection.remove(model , {merge: true});

            model.destroy({success: function(){
                Utils.alert(_this.messages.tagSaved);
                _this.collection.updateOrder();
            }, error: function(){
                _this.collection.add(model , {merge: true});
            }});

        },

        // /**
        //  * @desc
        //  *
        //  * @param event
        //  *
        //  */
        // searchContent: function(event){
        //
        // 	event.preventDefault();
        //
        // 	var _this = this;
        //
        // 	console.log('Search...');
        //
        // 	var $input = $(event.currentTarget);
        //
        // 	_this.$searchResults.empty().append('<li class="list-group-item color--gray-darkest text-xs-center font--sm-2 p-y-2"><span class="opacity--5"><span class="fa fa-spinner fa-pulse"></span><span class="m-l-quarter">Loading Content...</span></span></li>');
        //
        // 	Utils.delay(function(){
        //
        // 		var searchTerm = $input.val();
        //
        // 		console.log('Searching for...' + searchTerm);
        //
        // 		if(!searchTerm){ return; }
        //
        // 		_this.videoCollection.updateFetchOptions({
        // 			reset: true,
        // 			data: {
        // 				pageSize: 8,
        // 				searchTerm: searchTerm,
        // 				withRelated: 'video'
        // 			}
        // 		});
        //
        // 		_this.videoCollection.fetchPage();
        //
        // 	}, 250);
        //
        //
        // },

        // /**
        //  * @desc render search results
        //  */
        // renderSearchResults: function(){
        //
        // 	var _this = this;
        //
        // 	this.$searchResults.empty();
        //
        // 	_.each(this.videoCollection.toJSON(), function(item){
        //
        // 		_this.$searchResults.append('<li data-id="' + item.id + '" class="list-group-item color--gray-darkest cursor--pointer trigger-select-content">' + item.title + '</li>');
        //
        // 	});
        //
        // 	if(!this.videoCollection.length){
        // 		_this.$searchResults.empty().append('<li class="list-group-item color--gray-darkest text-xs-center font--sm-2 p-y-2"><span class="fa fa-warning"></span><span class="m-l-quarter">Nothing found. Try again.</span></li>');
        // 	}
        // }


    });

});
