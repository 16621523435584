/**
 * @desc App View
 *
 *
 */
define('views/admin-taxonomy-single',[

	'jquery',
	'underscore',
	'backbone',
	'utils',
	'underscore.string',
	'models/core',
    'views/edit-taxonomy-videos',
    'views/edit-taxonomy-series'

],	function($, _, Backbone, Utils, _string, Model, EditTaxVideosView, EditTaxSeriesView){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),

		// bind Events
		events: {
			'click .trigger-save-tag': 'save',
			'click .trigger-delete-tag' : 'delete',
			'keyup #name' : 'generateSlug',
			'keyup #slug' : 'generateSlug',
			'change .trigger-dropzone-update' : 'setImage',
			'click .trigger-remove-image' : 'removeImage',
			'change select#display_as': 'toggleContent'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			console.log('---Taxonomy Single View---');

			this.messages = Utils.getConfig().messages;
			this.user = new Model(Utils.getData('user'), {modelName: 'users'});

            this.model = new Model(Utils.getData('tag'), {modelName: 'tags'});
			this.is_new = this.model.isNew();

            this.editTaxVideosView = new EditTaxVideosView({
				videos: this.model.get('videos'),
				category: this.model
			});

            this.editTaxSeriesView = new EditTaxSeriesView({
                series: this.model.get('series'),
                category: this.model
            });

			Utils.initDropzone('image');

			this.render();
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){
			this.toggleContent();
		},

		/**
		 *
		 *
		 *
		 * @param event
		 */
		save: function ( event ){

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);
			var $form = $('#form-tag');

			var $name = $('input[name="name"]');
			var name = $name.val();
			var $slug = $('input[name="slug"]');
			var slug = $slug.val();

			if(!name){
				Utils.alert(this.messages.requiredTitle);
				$name.focus();
				return;
			}

			if(!slug){
				Utils.alert(this.messages.requiredSlug);
				$slug.focus();
				return;
			}

			Utils.buttonLoading($clicked);

			var data = $form.serializeObject();
			if(!data.parent_id){
				data.parent_id = null;
			}

			this.model.set(data);

			var hasChanged = _this.model.hasChanged('slug');

			this.model.save().always(function(response){
				if(response.status === 'error' || response.status === 400){
					Utils.alert(response.message);
					return;
				}
				Utils.alert(_this.messages.tagSaved);
				if(_this.is_new ){
					window.location = '/admin/taxonomy/' + _this.model.get('id');
				} else {
					Utils.buttonReset($clicked);
				}

			});

		},

		delete : function( event ) {

			var _this = this;

			event.preventDefault();

			var $clicked = $(event.currentTarget);

			Utils.buttonLoading($clicked);

			var check = window.confirm('Are you sure you want to delete this tag/category?');

			if(!check){
				Utils.buttonReset($clicked);
				return;
			}

			this.model.destroy().always(function(response){
				if(response.status === 'error'){
					Utils.alert(response.message);
					return;
				}

				Utils.alert(_this.messages.tagDeleted);
				window.location = '/admin/taxonomy';

			});

		},

		toggleContent: function () {
            var display_as = $('select#display_as').val();

			if (this.model){
                if(display_as === 'series_only' || display_as === 'multi_series'){
                    $('#find-edit-tax-series-wrapper').show();
                    $('#find-edit-tax-videos-wrapper').hide();
				} else {
                    $('#find-edit-tax-series-wrapper').hide();
                    $('#find-edit-tax-videos-wrapper').show();
				}
			}
        },

		/**
		 *
		 * setImage
		 *
		 * @param event
		 */
		setImage: function( event ){

			var _this = this;

			var $input = $(event.currentTarget);

			var image = $input.val();

			$('#' + $input.attr('data-updates')).css('background-image', "url(" + image + ")");

			var obj = {};

			obj[$input.attr('data-updates')] = $input.attr('data-file-name');

			this.model.set(obj);

			$('.trigger-remove-image').show();

		},


		/**
		 *
		 * removeImage
		 *
		 * @param event
		 */
		removeImage: function( event ){

			var _this = this;

			event.preventDefault();

			var data = {};

			var $button = $(event.currentTarget);

			data[$button.attr('data-remove')] = '';

			this.model.set(data);

			$button.hide();

			$('#' + $button.attr('data-remove')).css('background-image', "url()");

		},



		generateSlug: function( event ){

			event.preventDefault();

			var _this = this;

			Utils.delay(function(){

				if(_this.is_new || $(event.currentTarget).attr('id') === 'slug'){
					var title = $(event.currentTarget).val();
					var slug = _string.slugify(title);
					_this.model.set({
						slug: slug
					});
					$('#slug').val(slug);

				}

			}, 500);

		}


	});

});

