/**
 * @desc App View
 *
 *
 */
define('views/video',[
	'jquery',
	'underscore',
	'backbone',
	'utils',
	'tracking',
	'videojs-global',
	'videojs-youtube',
	'views/videos',
	'models/core',
    'js-cookie'

],	function($, _, Backbone, Utils, Tracking, videojs, VideoJsYT, VideosView, Model, Cookies){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),
		$nextVideos : $('#find-next-videos'),
		$nextSeries : $('#find-next-series'),
		$relatedVideos: $('#find-related-projects'),
		$singleVideo: $('.video-js'),
		$requireLoginModal: $('#modal-require-login'),


		// bind Events
		events: {

		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			var _this = this;

			console.log('Single view...');

			this.user = Utils.getData('user');
			this.watchHistory = new Model(null, {modelName: 'watch_history'});
			console.log('----User----');
			console.log(this.user);

			this.video = Utils.getData('video');

			console.log('---Video---');

			console.log(this.video)

			if(this.user){
				this.watchHistory = this.watchHistory.set(this.video.userWatchHistory);
				this.watchHistory.set({
					video_id: this.video.id,
					user_id: this.user.id
				});
				if(this.video.type === 'project'){
					this.watchHistory.save({
						watch_count: this.watchHistory.get('watch_count') ? (this.watchHistory.get('watch_count') + 1) : 1,
						complete_count: this.watchHistory.get('complete_count') ? (this.watchHistory.get('complete_count') + 1) : 1
					});
				}
				// already watched feature for single video
				if(this.watchHistory.attributes.watch_count >= 1){
					this.$singleVideo.css('opacity', 0.65);
				}
				console.log(this.user);
			}

			// track player
			if(this.video.video_id){

				this.videoPlayer = videojs('video--custom', {
					controls: false,
					techOrder: ["youtube"],
					sources: [{
						type: "video/youtube",
						src: "https://www.youtube.com/watch?v=" + _this.video.video_id
					}],
					youtube: {
						"ytControls": 2,
						"showinfo": 0,
						"playsinline": 1

					}
				});


				// Remove controls from the player on iPad to stop native controls from stealing
				// our click
				var contentPlayer =  document.getElementById('video--custom');
				if ((navigator.userAgent.match(/iPad/i) ||
					navigator.userAgent.match(/Android/i)) &&
					contentPlayer.hasAttribute('controls')) {
					contentPlayer.removeAttribute('controls');
				}

				// Initialize the ad container when the video player is clicked, but only the
				// first time it's clicked.
				var startEvent = 'click';
				if (navigator.userAgent.match(/iPhone/i) ||
					navigator.userAgent.match(/iPad/i) ||
					navigator.userAgent.match(/Android/i)) {
					startEvent = 'touchend';
				}

				// Require these instead of definiing so they don't block functionality when adblock clients block scripts
				// require(['videojs-contrib-ads', 'imasdk', 'videojs-ima'], function() {
				// 	_this.videoPlayer.ima({
				// 		id: 'video--custom',
				// 		adTagUrl: 'https://pubads.g.doubleclick.net/gampad/ads?sz=640x480&iu=/107853064/preroll_single_video&impl=s&gdfp_req=1&env=vp&output=vast&unviewed_position_start=1',
				// 		adLabel: '',
				// 		showCountdown: false,
				// 		showControlsForJSAds: false,
				// 		nativeControlsForTouch: false,
				// 		debug: false,
				// 		prerollTimeout: 1000
				// 	}, function(){
				// 		_this.videoPlayer.ima.startFromReadyCallback();
				// 	});
				// });

				this.videoPlayer.one(startEvent, function() {

                    // If the guest is logged out, set a cookie so we can limit video plays
                    if(!_this.user){
                        // Set the cookie to 0 if it's NaN
                        var userWatched = Cookies.get('user_watched') ? parseInt(Cookies.get('user_watched')) : 0;
                        // Don't increment more than we have to
                        if(userWatched < 1){
                            Cookies.set('user_watched', userWatched ? userWatched + 1 : 1);
                        } else{
                        	// If the guest has used their watches, launch a modal
                            _this.$requireLoginModal.modal('show');
			    _this.videoPlayer.pause();
                            return;
                        }
                    }

					_this.videoPlayer.ima.initializeAdDisplayContainer();
					_this.videoPlayer.ima.requestAds();
					$('#video--custom_ima-fullscreen-div').remove();
					_this.videoPlayer.play();

				});

				this.trackVideo();
			}

			// render related videos
			if(this.$relatedVideos.length){
				this.relatedView = new VideosView({
					without: this.video.id,
					$view: this.$relatedVideos,
					fetchOptions: {
						urlPath : '/related',
						data: {
							where: {active: true},
							pageSize: 6,
							video_id: this.video.id,
						}
					}

				});
			}

			// render up next videos
			if(this.$nextVideos.length) {
				console.log('--Render Up Next');
				this.upNextView = new VideosView({
					without: this.video.id,
					templateType: 'list',
					$view: this.$nextVideos,
					fetchOptions: {
						data: {
							category: this.video.categories.length ? (this.video.categories.length > 1) ? this.video.categories[1].slug : this.video.categories[0].slug : null,
							where: {type: this.video.type, active: true},
							pageSize: 6,
							video_id: this.video.id,
						}
					}
				});
				if(this.video.categories.length){
					$('.view-all').attr('href', '/category/' + ((this.video.categories.length > 1) ? this.video.categories[1].slug : this.video.categories[0].slug));
				}
			}

			if(this.$nextSeries.length) {
				console.log('--Render Next Series');
				this.upNextView = new VideosView({
					$view: this.$nextSeries,
					series: Utils.getData('series'),
					currentVideo: this.video,
					videos: Utils.getData('seriesVideos'),
					fetchOptions: {}
				});
			}

			// track view
			Tracking.track({
				type: 'view',
				video_id: this.video.id,
				user_id: this.user ? this.user.id : null
			});

		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){



		},

		checkGuestViews: function () {

        },

		trackVideo: function(){

			var _this = this;
			var sessionWatchTime = 0;
			var totalWatchTime = _this.watchHistory.get('watch_time') ? _this.watchHistory.get('watch_time') : 0;
			var playing = false;
			var paused = false;
			var completed = false;
			var watched = false;

			this.videoPlayer.on('pause', function(){
				console.log('-------PAUSE---------')
				paused = true;
				playing = false;
			});

			this.videoPlayer.on('play', function(){
				console.log('-------PLAY---------');
				paused = false;
				playing = true;
			});

			this.videoPlayer.on('ended', function(){
				console.log('-------END---------')
				paused = true;
				playing = false;
				watched = false;
				completed = false;
				sessionWatchTime = 0;

				if(_this.user) {
					// save position to 0
					_this.watchHistory.save({
						current_position: 0,
						watch_count: _this.watchHistory.get('watch_count') ? (_this.watchHistory.get('watch_count') + 1) : 1,
						complete_count: _this.watchHistory.get('complete_count') ? (_this.watchHistory.get('complete_count') + 1) : 1
					});
				}
			});

			this.videoPlayer.one('play', function(){

				console.log('-----Played-----');
				playing = true;

				Tracking.track({
					type: 'play',
					video_id: _this.video.id,
					user_id: _this.user ? _this.user.id : null
				});

				if(_this.user) {
					// reset player on ended vide
					if (_this.watchHistory.get('current_position') >= (_this.videoPlayer.duration() - 5)) {
						_this.videoPlayer.currentTime(0);
					}

					if (_this.watchHistory.get('current_position')) {
						_this.videoPlayer.currentTime(_this.watchHistory.get('current_position'));
					}

					// watch history play
					_this.watchHistory.save({
						duration: _this.videoPlayer.duration(),
						current_position: _this.videoPlayer.currentTime(),
						watch_time: totalWatchTime,
						play_count: _this.watchHistory.get('play_count') ? (_this.watchHistory.get('play_count') + 1) : 1
					});
				}

				setInterval(function(){

					console.log('-----Interval-----');

					if(paused){
						console.log('-----Paused-----');
						return;
					}

					totalWatchTime =  totalWatchTime + 10;
					sessionWatchTime =  sessionWatchTime + 10;

					console.log(sessionWatchTime / _this.videoPlayer.duration())

					// Watched
					if((sessionWatchTime / _this.videoPlayer.duration()) > 0.51 && watched === false){
						console.log('-----Watched-----');
						watched = true;
						Tracking.track({
							type: 'watch',
							video_id: _this.video.id,
							user_id: _this.user ? _this.user.id : null
						});
						if(_this.user) {
							if (_this.user && _this.watchHistory.get('id')) {
								console.log('-----WatchHistory Watched-----');
								_this.watchHistory.save({
									watch_count: _this.watchHistory.get('watch_count') ? (_this.watchHistory.get('watch_count') + 1) : 1
								});
							}
						}
					}

					// track and save completed
					if((sessionWatchTime / _this.videoPlayer.duration()) > 0.90 && completed === false){

						console.log('-----Completed-----');
						completed = true;
						Tracking.track({
							type: 'complete',
							video_id: _this.video.id,
							user_id: _this.user ? _this.user.id : null
						});

						if(_this.user) {
							if (_this.user && _this.watchHistory.get('id')) {
								console.log('-----WatchHistory Complete-----');
								_this.watchHistory.save({
									complete_count: _this.watchHistory.get('complete_count') ? (_this.watchHistory.get('complete_count') + 1) : 1
								});
							}
						}
					}

					// save current position
					if(_this.user && _this.watchHistory.get('id')) {
						console.log('-----WatchHistory-----');
						_this.watchHistory.save({
							current_position: _this.videoPlayer.currentTime(),
							watch_time: totalWatchTime
						});
					}
				}, 10000);

			});


		}

	});

});

