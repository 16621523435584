/**
 * @desc App View
 *
 *
 */
define('views/app',[
	'jquery',
	'underscore',
	'backbone',
	'utils',
	'models/core',
	'vide',
	'videojs',
	'videojs-youtube',
	'Flickity'

],	function($, _, Backbone, Utils, Model, vide, videojs, videojsyoutube, Flickity){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),

		// bind Events
		events: {
			'click .trigger-scroll-to': 'scrollTo',
			'click .difficulty-meter-hover .trigger-filter-videos': 'toggleMeter',
			'click .hero-box a': 'heroboxClick'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			$('.cover-video').data('vide');
			
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			// No render

		},

		/**
		 *
		 * scrollTo()
		 *
		 * @param event
		 */
		scrollTo: function ( event ){
			event.preventDefault();

			var $clicked = $(event.currentTarget);
			var $target = $($clicked.attr('data-scroll-to'));
			var offset = $clicked.attr('data-scroll-offset');
			$('html, body').animate({
				scrollTop: $target.offset().top - (offset ? offset : 0)
			}, 500);

			if($clicked.parents('.collapse')){
				$clicked.parents('.collapse').removeClass('in');
			}
		},

		/**
		 *
		 * heroboxClick()
		 *
		 * @param event
		 */
		heroboxClick: function ( event ){
			var $clicked = $(event.currentTarget);
			var href = $clicked.attr('href');

			if(href.charAt(0) == '#' ) {
				event.preventDefault();

				var $target = $(href);
				if($target) {
					$('html, body').animate({
						scrollTop: $target.offset().top
					}, 500);	
				}
			}
		},

		/**
		 *
		 * toggleMeter()
		 * @desc toggle the home page meter feature.
		 *
		 * @param event
		 */
		toggleMeter: function( event ){

			var $clicked = $(event.currentTarget);
			var at = $clicked.attr('data-key');

			$('.difficulty-meter-hover .meter-step').each(function(index){

				var $meter = $(this);

				if((index + 1) <= at){
					$meter.addClass('active');
				} else {
					$meter.removeClass('active');
				}

			});

		}

	});

});

