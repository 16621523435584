/**
 * @desc App View
 *
 *
 */
define('views/email-signup',[

	'jquery',
	'underscore',
	'backbone',
	'utils',

],	function($, _, Backbone, Utils){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),
		$modalSignup : $('#modal-signup'),
		$modalCertification : $('#modal-certification'),

		// bind Events
		events: {
			'click .trigger-email-signup': 'emailSignup'
			
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){
			
			console.log('---Email Signup View-----');
			
			this.messages = Utils.getConfig().messages;
			this.user = Utils.getData('user');
			
		},

		/**
		 * render()
		 * @desc Render the view
		 *
		 *
		 */
		render: function(){

			// No render

		},


		emailSignup: function( event ){

			var _this = this;
			
			event.preventDefault();
			
			var $button = $(event.currentTarget);

			var form = $button.parents('form');
			
			var $email = form.find('input[name="email"]');
			var email = $email.val().toLowerCase();

			var listId = form.find('input[name="list_id"]');

			// email required
			if(!email){
				Utils.alert( this.messages.requiredEmail );
				$email.focus();
				return;
			}
			
			if(!Utils.validateEmail(email)){
				Utils.alert( this.messages.invalidEmail );
				$email.focus();
				return;
			}

			Utils.buttonLoading($button);
			
			$.post(form.attr('action'), form.serialize(), function(response) {

				Utils.buttonReset($button);
				
				if(_this.$modalCertification.length){
					_this.$modalCertification.modal('hide');
				}
				

				if(!_this.user){
					_this.$modalSignup.find('input[name="email"]').val(email);
					_this.$modalSignup.modal('show');
					_this.$modalSignup.on('shown.bs.modal', function(){
						_this.$modalSignup.find('input[name="password"]').focus();
					});
					require(['views/auth'], function (AuthView) {
						var authView = new AuthView({});
					});
				} else {
					$email.val('');
					Utils.alert(_this.messages.emailSignupSuccess, 5000);
				}

			}, 'json');
			
		}

		


	});

});
