/**
 * @desc App View
 *
 *
 */
define('views/videos',[

	'jquery',
	'underscore',
	'underscore.string',
	'backbone',
	'utils',
	'tracking',
	'models/core',
	'collections/core',
	'videojs-global',
	'videojs-youtube',
	'text!../templates/videos-featured.html',
	'text!../templates/videos-cards.html',
	'text!../templates/videos-list.html'

],	function($, _, _string, Backbone, Utils, Tracking, Model, VideoCollection, videojs, VideoJsYT, FeaturedVideoTemplate, VideoCardsTemplate, ListVideoTemplate){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),
		$view : $('#find-view-video'),
		FeaturedVideoTemplate: _.template(FeaturedVideoTemplate),
		VideoCardsTemplate: _.template(VideoCardsTemplate),
		ListVideoTemplate: _.template(ListVideoTemplate),

		// bind Events
		events: {
			'keyup .trigger-search-videos' : 'searchVideos',
			'click .trigger-filter-videos' : 'applyFilter'
		},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			console.log('Videos view...');

			var _this = this;

			this.options = options;
			this.admin = options.admin;
			this.series = options.series;
			this.without = options.without;
			this.videos = options.videos ? options.videos : Utils.getData('videos');
			this.videoType = (options.fetchOptions && options.fetchOptions.data && options.fetchOptions.data.videoType) ? options.fetchOptions.data.videoType : 'videos';
			this.currentVideo = options.currentVideo;

			if(options.$view){
				this.$view = options.$view;
			}

			Utils.viewLoading(this.$view);

			this.user = Utils.getData('user');

			this.template = this.VideoCardsTemplate;

			if(options.templateType === 'featured') {
				this.template = this.FeaturedVideoTemplate;
			}
			if(options.templateType === 'list') {
				this.template = this.ListVideoTemplate;
			}

			// Set the Model to be fetched based on fetch options
			var videoType = this.videoType;
			var videoFetchModel = videoType ? videoType : 'videos';

			this.videoCollection = new VideoCollection(this.videos, {
				pagination: options.pagination,
				modelName: videoFetchModel,
				fetchOptions: options.fetchOptions,
			});

			this.listenTo(this.videoCollection, 'sync', this.render);
			this.listenTo(this.videoCollection, 'request', function(){

				if(_this.videoCollection.fetchOptions.reset){
					Utils.viewLoading(_this.$view);
				}

			});

			var category_video = $('#category-video');
			var video_url = category_video.data('video');
			
			
			if(category_video.length > 0 && video_url != '') {
				
				this.videoPlayer = videojs('category-video', {
					controls: false,
					techOrder: ["youtube"],
					sources: [{
						type: "video/youtube",
						src: video_url
					}],
					youtube: {
						"ytControls": 2,
						"showinfo": 0,
						"rel": 0,
						"playsinline": 1,
						"modestbranding": 1
					}
				});

				// Remove controls from the player on iPad to stop native controls from stealing
				// our click
				var contentPlayer =  document.getElementById('video--custom');
				if ((navigator.userAgent.match(/iPad/i) ||
					navigator.userAgent.match(/Android/i)) &&
					contentPlayer.hasAttribute('controls')) {
					contentPlayer.removeAttribute('controls');
				}

				// Initialize the ad container when the video player is clicked, but only the
				// first time it's clicked.
				var startEvent = 'click';
				if (navigator.userAgent.match(/iPhone/i) ||
					navigator.userAgent.match(/iPad/i) ||
					navigator.userAgent.match(/Android/i)) {
					startEvent = 'touchend';
				}


				this.videoPlayer.one(startEvent, function() {
					_this.videoPlayer.play();
				});
			}

			if(!this.videoCollection.length){
				this.videoCollection.fetchPage();
			} else {
				this.render();
			}
		},

		/**
		 * @desc Render the view
		 */
		render: function(){

			var _this = this;

			console.log('Render video grid...');

			var videoCollection = this.videoCollection.toJSON();

			// Added 12/2017 - gb
			// If the video_tags model is set in Fetch options, get the videos from the array.
			// Todo: Cleaner way to simplify this array?
			if(this.videoType === 'video_tags'){
				 var models = [];
                _.each(videoCollection, function ( model ) {
                    if(model.video){
                        models.push(model.video);
					}
                });
                videoCollection = models;
			}

            console.log('----Videos----');
            console.log(videoCollection);

			var videos = _.filter(videoCollection, function(video){

				// determine if video.id is in watchHistoryIDs array
				if(_this.user){
					_.find(_this.user.watchHistoryIDs, function(videoHistory) {
						if(videoHistory.video_id === video.id){
							video.has_been_watched = true;
						} else {
							video.has_been_watched = false;
						}

					});
				}

				return video.id !== _this.without;
			});

			this.$view.empty().append(this.template({
				videos: videos,
				_string: _string,
				admin: this.admin,
				series: this.series,
				utils: Utils,
				currentVideo: this.currentVideo
			}));

			Utils.viewReset(this.$view);

		},

		/**
		 * @desc search videos
		 */
		searchVideos: function( event ){

			event.preventDefault();

			var _this = this;

			console.log('Search...');

			var $input = $(event.currentTarget);

			Utils.delay(function(){

				var searchTerm = $input.val();

				console.log('Searching for...' + searchTerm);

				_this.videoCollection.pagination.set({page: 1});
				_this.videoCollection.updateFetchOptions({
					reset : true,
					data:{
						searchTerm: searchTerm
					}
				});

				_this.videoCollection.fetchPage();

				_this.videoCollection.updateFetchOptions({
					reset : false
				});

				Tracking.track({
					type: 'search',
					user_id: _this.user ? _this.user.id : null,
					meta: {term: searchTerm}
				});

			}, 500);


		},

		/**
		 * @desc filter videos
		 */
		applyFilter: function( event ) {

			event.preventDefault();

			this.videoCollection.pagination.set({page: 1});

			var $clicked = $(event.currentTarget);

			var type = $clicked.attr('data-type');
			var key = $clicked.attr('data-key');
			var display = $clicked.attr('data-display');

			var $parent = $('#filter-' + type);

			console.log('Filter...' + key);

			if(key === 'all'){
				$parent.removeClass('filter-active');
			} else {
				$parent.addClass('filter-active');
			}

			$parent.text(display);

			if(type === 'type'){
				if(key === 'all'){
					this.videoCollection.updateFetchOptions({
						data : { orWhere: {} }
					});
					delete this.videoCollection.fetchOptions.data.where.type;
				} else {
					this.videoCollection.updateFetchOptions({
						data : { orWhere: {}, where: {type : key}}
					});
				}
			}

			if(type === 'industry'){
				if(key === 'all'){
					delete this.videoCollection.fetchOptions.data.where.industry;
				} else {
					this.videoCollection.updateFetchOptions({
						data : { orWhere: {}, where: {industry : key}}
					});
				}
			}

			if(type === 'difficulty'){
				if(key === 'all'){
					delete this.videoCollection.fetchOptions.data.where.difficulty;
				} else {
					this.videoCollection.updateFetchOptions({
						data : { orWhere: {}, where: {difficulty : key}}
					});
				}
			}

			if(type === 'sort'){
				if(key === 'created_at'){
					this.videoCollection.updateFetchOptions({
						data : { orderBy: 'created_at', order: 'desc'}
					});
				}
				if(key === 'popularity'){
					this.videoCollection.updateFetchOptions({
						data : { orderBy: key, order: 'desc'}
					});
				}
				if(key === 'featured_order'){
					this.videoCollection.updateFetchOptions({
						data : { orderBy: 'featured_order', order: 'asc'}
					});
				}
			}

			this.videoCollection.updateFetchOptions({
				reset : true
			});
			this.videoCollection.fetchPage();
			this.videoCollection.updateFetchOptions({
				reset : false
			});

		}

	});

});
