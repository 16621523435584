define('tracking',[

	'jquery',
	'models/core',

],	function($, Model){
	
	return {
		/**
		 * track()
		 *
		 * @param {Object} data (all the data!)
		 * @param {function} callback
		 *
		 */
		track : function(data, callback){
			if(!data){return;}
			new Model(null, {modelName: 'track'}).save(data, {
				success: function(m,r,o){
					if(callback){
						callback(m);
					}
				}
			});
		},
		
	};
	
});


