define('views/series',[
	'jquery',
	'underscore',
	'backbone',
	'utils',
	'underscore.string',
	'models/core',
	'collections/core',
	'text!templates/series-card.html'

],	function($, _, Backbone, Utils, _string, Model, Collection, Template){

	'use strict';

	return Backbone.View.extend({

		// setup DOM Elements
		el : $('body'),
		template: _.template(Template),

		// bind Events
		events: {},

		/**
		 * initialize()
		 * @desc initialize the view
		 *
		 * @param options
		 */
		initialize: function(options){

			console.log('---Series View---');
			this.admin = options.admin;
			this.$view = options.$view ? options.$view : $('#find-series');
			this.series = options.series ? options.series : Utils.getData('series');
			this.messages = Utils.getConfig().messages;
			this.user = Utils.getData('user');

			this.collection = new Collection(this.series, {
				modelName: 'series',
				pagination: Utils.getData('pagination'),
				fetchOptions: Utils.getData('paginationOptions')
			});

			this.listenTo(this.collection, 'sync', this.render);
			
			Utils.viewLoading(this.$view);
			
			this.render();
		},

		/**
		 * @desc Render the view
		 */
		render: function(){
			
			this.$view.empty().append(this.template({
				collection: this.collection.toJSON(),
				_string: _string,
				admin: this.admin,
				utils: Utils
			}));

			Utils.viewReset(this.$view);

		}


	});

});
